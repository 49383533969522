/* ----------------------------------------------------------
    ENV CONSTANT
----------------------------------------------------------- */

export const API_KEY = 'REACT_APP_API_KEY';

/* ----------------------------------------------------------
  ACTION VERBS
----------------------------------------------------------- */

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

/*----------------------------------------------------------
  METHODS
----------------------------------------------------------- */

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

/* ----------------------------------------------------------
  APPLICATION CONSTANTS
----------------------------------------------------------- */

export const IS_AUTHENTICATED = 'isAuthenticated';
export const ENVIRONMENT = 'environment';

export const MAX_FILE_SIZE = 2000000;

export const SEARCH_ELEMENTS_PER_PAGE= 20;
export const SEARCH_ALL_ELEMENTS_PER_PAGE = 50;
export const ELEMENTS_PER_PAGE = 20;
