import { produce } from 'immer';
import { COMMENT } from 'core/actions/InteractionActions';
import {
  EXPERTISE_RESOURCE,
  EXPERTISE_RESOURCES_PAGINATED,
  EXPERTISE_RESOURCE_COMMENTS,
  EXPERTISE_RESOURCE_COMMENTS_LIKE,
  EXPERTISE_RESOURCE_LIKE,
  EXPERTISE_RESOURCE_PROJECT,
  EXPERTISE_RESOURCE_TAGS,
  EXPERTISE_RESOURCE_VIEW,
  KNOWLEDGE_RESOURCE,
  KNOWLEDGE_RESOURCES_PAGINATED,
  KNOWLEDGE_RESOURCE_COMMENTS,
  KNOWLEDGE_RESOURCE_COMMENTS_LIKE,
  KNOWLEDGE_RESOURCE_LIKE,
  KNOWLEDGE_RESOURCE_TAGS,
  KNOWLEDGE_RESOURCE_VIEW,
  LINK_RESOURCE,
  LINK_RESOURCES_PAGINATED,
  LINK_RESOURCE_LIKE,
  LINK_RESOURCE_TAGS,
  LINK_RESOURCE_VIEW,
  PRIMITIVE_RESOURCES,
  RESOURCES,
  RESOURCES_PAGINATED,
  TOOL_CONTAINED_RESOURCE_LIKE,
  TOOL_RESOURCE,
  TOOL_RESOURCES_PAGINATED,
  TOOL_RESOURCE_COMMENTS,
  TOOL_RESOURCE_COMMENTS_LIKE,
  TOOL_RESOURCE_DOCUMENTS,
  TOOL_RESOURCE_LIKE,
  TOOL_RESOURCE_RESOURCES,
  TOOL_RESOURCE_TAGS,
  TOOL_RESOURCE_VIEW,
  UPDATE_EXPERTISE_LINKED_RESOURCES
} from 'core/actions/ResourceActions';
import { IActivity, IExpertiseResource, IKnowledgeResource, ILinkResource, IToolResource } from 'core/models/Models';

export interface IResourceState {
  expertiseResources: IExpertiseResource[];
  knowledgeResources: IKnowledgeResource[];
  linkResources: ILinkResource[];
  toolResources: IToolResource[];
  expertiseAllResources: IExpertiseResource[];
  knowledgeAllResources: IKnowledgeResource[];
  linkAllResources: ILinkResource[];
  toolAllResources: IToolResource[];
  expertiseLinkedResources: ILinkedResourceState;
  toolLinkedResources: ILinkedResourcesState;
  resourcesLoaded: boolean;
  currentResource: any;
  isFinalPage: {
    all: boolean;
    link: boolean;
    knowledge: boolean;
    expertise: boolean;
    tool: boolean;
  };
}

interface ILinkedResourcesState {
  expertiseResources: IExpertiseResource[];
  knowledgeResources: IKnowledgeResource[];
  linkResources: ILinkResource[];
}

interface ILinkedResourceState {
  knowledgeResource: IKnowledgeResource | undefined;
  linkResource: ILinkResource | undefined;
}

const emptyExpertiseLinkedResources: ILinkedResourceState = {
  knowledgeResource: undefined,
  linkResource: undefined
};

const emptyToolLinkedResources: ILinkedResourcesState = {
  expertiseResources: [],
  knowledgeResources: [],
  linkResources: []
};

const initialState: IResourceState = {
  expertiseResources: [],
  knowledgeResources: [],
  linkResources: [],
  toolResources: [],
  expertiseAllResources: [],
  knowledgeAllResources: [],
  linkAllResources: [],
  toolAllResources: [],
  expertiseLinkedResources: emptyExpertiseLinkedResources,
  toolLinkedResources: emptyToolLinkedResources,
  resourcesLoaded: false,
  currentResource: null,
  isFinalPage: {
    all: false,
    link: false,
    knowledge: false,
    expertise: false,
    tool: false
  }
};

function updateResourceArray(stateArray, payloadArray) {
  // Create a Set of IDs for quick lookup to check for duplicates
  const existingIds = new Set(stateArray.map(item => item.attributes.id));

  // Filter out duplicates from the payload array
  const uniquePayloadArray = payloadArray.filter(item => !existingIds.has(item.attributes.id));

  // Combine the existing array with the unique items from the payload
  return [...stateArray, ...uniquePayloadArray];
}

function replaceItemWithPayload(resources, payload) {
  return resources.map(item =>
    item.attributes.pId === payload.attributes?.pId ? payload : item
  );
}

function updateTagsInResources(resources, payload) {
  return resources.map(item =>
    (item.attributes.pId === payload.id || item.attributes.pId === payload.resourceId) ? { ...item, tags: payload.tags } : item
  );
}

function removeItem(resources, payload) {
  return resources?.filter(
    resource => resource.attributes?.pId !== payload?.id
  );
}

function updateLikes(item, payload) {
  if (item?.attributes?.pId === payload?.elementId) {
    const userId = payload?.userId;
    let likedByIds = item?.attributes?.likedByIds ?? [];

    // Using Immer, avoid direct mutation. Instead, create a new array.
    if (likedByIds.includes(userId)) {
      // Remove like
      likedByIds = likedByIds.filter(id => id !== userId);
    } else {
      // Add like
      likedByIds = [...likedByIds, userId];
    }

    return {
      ...item,
      attributes: { ...item.attributes, likedByIds }
    };
  }
  return item;
}

function addCommentToResource(resources, payload) {
  return resources.map(resource => {
    if (resource.attributes?.pId === payload.elementId) {
      // Check if the comment is already present in resource comments to avoid duplicates
      const isCommentPresent = resource.comments?.some(comment => comment.pId === payload.pId);
      if (!isCommentPresent) {
        const newComments = resource.comments ? [...resource.comments, payload] : [payload];
        return { ...resource, comments: newComments };
      }
    }
    return resource;
  });
}

function removeCommentFromResource(resources, payload) {
  return resources.map(resource => {
    if (resource.attributes.pId === payload.elementId) {
      // Check if the comment to be removed is present in the resource's comments
      const isCommentPresent = resource.comments?.some(comment => comment.pId === payload.pId);

      if (isCommentPresent) {
        // Remove the specified comment from the comments array
        const updatedComments = resource.comments?.filter(comment =>
          comment.pId !== payload.pId && comment?.parentId !== payload.pId
        );
        return { ...resource, comments: updatedComments };
      }
    }
    return resource;
  });
}

function updateCommentsInResources(resources, payload) {
  return resources.map(resource =>
    resource.attributes.pId === payload.elementId
      ? { ...resource, comments: resource.comments?.map(comment => comment.pId === payload.pId ? payload : comment) }
      : resource
  );
}


function updateOrAddResource(allResourcesName, resourcesName, payload, draft) {
  // Search for the resource in the first array (e.g., allResourcesName)
  const indexInAllResources = draft[allResourcesName].findIndex(item =>
    item?.attributes?.id === payload?.attributes?.id
  );

  if (indexInAllResources !== -1) {
    // Resource found in allResourcesName, update it
    draft[allResourcesName][indexInAllResources] = payload;
  } else {
    // Resource not found in allResourcesName, search in the second array (e.g., resourcesName)
    const indexInResources = draft[resourcesName].findIndex(item =>
      item?.attributes?.id === payload?.attributes?.id
    );

    if (indexInResources !== -1) {
      // Resource found in resourcesName, update it
      draft[resourcesName][indexInResources] = payload;
    } else {
      // Resource not found in both arrays, add it to allResourcesName
      draft[allResourcesName].push(payload);
    }
  }
}

function updateResourceProjects(resources, payload) {
  return resources.map(resource => {
    if (resource.attributes?.pId === payload.project.resourceId) {
      // Map through the projects and replace the project that matches the payload's project ID
      const updatedProjects = resource.projects.map(project =>
        project.pId === payload.project.pId ? payload.project : project
      );
      return { ...resource, projects: updatedProjects };
    }
    return resource;
  });
}

function updateResourcesWithNewProject(resources, payload) {
  return resources.map(resource => {
    if (resource.attributes?.pId === payload.project.resourceId) {
      // Update the projects array with the new project
      const newProjects = resource.projects ? [...resource.projects, payload.project] : [payload.project];
      return { ...resource, projects: newProjects };
    }
    return resource;
  });
}

function removeProjectFromResources(resources, payload) {
  return resources.map(resource => {
    if (resource.attributes.pId === payload.resourceId) {
      // Filter out the project with the specified projectId
      const updatedProjects = resource.projects.filter(project => project.pId !== payload.projectId);
      return { ...resource, projects: updatedProjects };
    }
    return resource;
  });
}

function updateResourceDocuments(resources, payload) {
  return resources.map(resource => {
    if (resource.attributes.pId === payload.elementId) {
      // Map through the documents and replace the document that matches the payload's document ID
      const updatedDocuments = resource.documents.map(document =>
        document.pId === payload.pId ? payload : document
      );
      return { ...resource, documents: updatedDocuments };
    }
    return resource;
  });
}

function addDocumentToResource(resources, payload) {
  return resources.map(resource => {
    if (resource.attributes?.pId === payload.elementId) {
      // Add the new document to the documents array
      const newDocuments = resource.documents ? [...resource.documents, payload] : [payload];
      return { ...resource, documents: newDocuments };
    }
    return resource;
  });
}

function removeDocumentFromResource(resources, payload) {
  return resources.map(resource => {
    if (resource.attributes.pId === payload.elementId) {
      // Filter out the document with the specified document ID
      const updatedDocuments = resource.documents.filter(document => document.pId !== payload.pId);
      return { ...resource, documents: updatedDocuments };
    }
    return resource;
  });
}



const resourceReducer = (state = initialState, action: IActivity): IResourceState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case UPDATE_EXPERTISE_LINKED_RESOURCES:
        draft.expertiseLinkedResources.linkResource = payload.linkResource;
        draft.expertiseLinkedResources.knowledgeResource = payload.knowledgeResource;
        break;
        case RESOURCES.GET.SUCCESS:
          draft.expertiseResources = payload.expertiseResources;
          draft.knowledgeResources = payload.knowledgeResources;
          draft.linkResources = payload.linkResources;
          draft.toolResources = payload.toolResources;
          draft.resourcesLoaded = true;
          break;
      case RESOURCES_PAGINATED.GET.SUCCESS:
        draft.expertiseAllResources = updateResourceArray(state.expertiseAllResources, payload.expertiseResources);
        draft.knowledgeAllResources = updateResourceArray(state.knowledgeAllResources, payload.knowledgeResources);
        draft.linkAllResources = updateResourceArray(state.linkAllResources, payload.linkResources);
        draft.toolAllResources = updateResourceArray(state.toolAllResources, payload.toolResources);
        draft.isFinalPage = {...state.isFinalPage, all: payload.isFinalPage}
        // draft.resourcesLoaded = true;
        break;
      case KNOWLEDGE_RESOURCES_PAGINATED.GET.SUCCESS:
        draft.knowledgeResources = updateResourceArray(state.knowledgeResources, payload.knowledgeResources);
        draft.isFinalPage = {...state.isFinalPage, knowledge: payload.isFinalPage}
        break;
      case EXPERTISE_RESOURCES_PAGINATED.GET.SUCCESS:
        draft.expertiseResources = updateResourceArray(state.expertiseResources, payload.expertiseResources);
        draft.isFinalPage = {...state.isFinalPage, expertise: payload.isFinalPage}
        break;
      case LINK_RESOURCES_PAGINATED.GET.SUCCESS:
        draft.linkResources = updateResourceArray(state.linkResources, payload.linkResources);
        draft.isFinalPage = {...state.isFinalPage, link: payload.isFinalPage}
        break;
      case TOOL_RESOURCES_PAGINATED.GET.SUCCESS:
        draft.toolResources = updateResourceArray(state.toolResources, payload.toolResources);
        draft.isFinalPage = {...state.isFinalPage, tool: payload.isFinalPage}
        break;
      case PRIMITIVE_RESOURCES.GET.SUCCESS:
        draft.expertiseResources = payload?.expertiseResources ? payload.expertiseResources : state.expertiseResources;
        draft.knowledgeResources = payload?.knowledgeResources ? payload.knowledgeResources : state.knowledgeResources;
        draft.linkResources = payload?.linkResources ? payload.linkResources : state.linkResources;
        break;
      case KNOWLEDGE_RESOURCE.GET.SUCCESS:
        if (state.knowledgeResources.length === 0) {
          draft.knowledgeResources.push(payload);
        } else {
          const updatedKnowledgeResources = state.knowledgeResources.filter(
            (contest) => contest.attributes.pId !== payload.attributes?.pId
          );
          updatedKnowledgeResources.push(payload);
          draft.knowledgeResources = updatedKnowledgeResources;
        }
        break;
      case KNOWLEDGE_RESOURCE_VIEW.POST.SUCCESS:
        updateOrAddResource('knowledgeAllResources', 'knowledgeResources', payload, draft);
        break;
      case KNOWLEDGE_RESOURCE.POST.SUCCESS: {
        const resource = { ...payload };
        const source = payload.source;
        delete resource['source'];

        draft.knowledgeResources.unshift(resource);
        draft.knowledgeAllResources.unshift(resource);
        if (source == 'expertise') {
          draft.expertiseLinkedResources.knowledgeResource = resource;
        }
        break;
      }
      case KNOWLEDGE_RESOURCE.PUT.SUCCESS:
        draft.knowledgeResources = replaceItemWithPayload(state.knowledgeResources, payload);
        draft.knowledgeAllResources = replaceItemWithPayload(state.knowledgeAllResources, payload);
        break;
      case KNOWLEDGE_RESOURCE_TAGS.POST.SUCCESS:
        draft.knowledgeResources = updateTagsInResources(state.knowledgeResources, payload);
        draft.knowledgeAllResources = updateTagsInResources(state.knowledgeAllResources, payload);
        break;
      case KNOWLEDGE_RESOURCE.DELETE.SUCCESS: {
        draft.knowledgeResources = removeItem(state.knowledgeResources, payload);
        draft.knowledgeAllResources = removeItem(state.knowledgeAllResources, payload);
        break;
      }
      case EXPERTISE_RESOURCE.DELETE.SUCCESS:
        draft.expertiseResources = removeItem(state.expertiseResources, payload);
        draft.expertiseAllResources = removeItem(state.expertiseAllResources, payload);
        break;
      case EXPERTISE_RESOURCE.POST.SUCCESS: {
        draft.expertiseResources.unshift(payload);
        draft.expertiseAllResources.unshift(payload);
        break;
      }
      case EXPERTISE_RESOURCE_VIEW.POST.SUCCESS:
      case EXPERTISE_RESOURCE.PUT.SUCCESS:
        updateOrAddResource('expertiseAllResources', 'expertiseResources', payload, draft);
        break;
      case EXPERTISE_RESOURCE_TAGS.POST.SUCCESS:
        draft.expertiseResources = updateTagsInResources(state.expertiseResources, payload);
        draft.expertiseAllResources = updateTagsInResources(state.expertiseAllResources, payload);
        break;
      case EXPERTISE_RESOURCE_PROJECT.PUT.SUCCESS:
        draft.expertiseResources = updateResourceProjects(state.expertiseResources, payload);
        draft.expertiseAllResources = updateResourceProjects(state.expertiseAllResources, payload);
        break;
      case EXPERTISE_RESOURCE_PROJECT.POST.SUCCESS:
        draft.expertiseResources = updateResourcesWithNewProject(state.expertiseResources, payload);
        draft.expertiseAllResources = updateResourcesWithNewProject(state.expertiseAllResources, payload);
        break;
      case EXPERTISE_RESOURCE_PROJECT.DELETE.SUCCESS:
        draft.expertiseResources = removeProjectFromResources(state.expertiseResources, payload);
        draft.expertiseAllResources = removeProjectFromResources(state.expertiseAllResources, payload);
        break;
      case EXPERTISE_RESOURCE_COMMENTS.PUT.SUCCESS:
      case EXPERTISE_RESOURCE_COMMENTS_LIKE.POST.SUCCESS:
        draft.expertiseResources = updateCommentsInResources(state.expertiseResources, payload);
        draft.expertiseAllResources = updateCommentsInResources(state.expertiseAllResources, payload);
        break;
      case EXPERTISE_RESOURCE_COMMENTS.POST.SUCCESS:
        draft.expertiseResources = addCommentToResource(state.expertiseResources, payload);
        draft.expertiseAllResources = addCommentToResource(state.expertiseAllResources, payload);
        break;
      case EXPERTISE_RESOURCE_COMMENTS.DELETE.SUCCESS:
        draft.expertiseResources = removeCommentFromResource(state.expertiseResources, payload);
        draft.expertiseAllResources = removeCommentFromResource(state.expertiseAllResources, payload);
        break;
      case EXPERTISE_RESOURCE_LIKE.POST.SUCCESS:
        draft.expertiseResources = state.expertiseResources.map((item) => updateLikes(item, payload));
        draft.expertiseAllResources = state.expertiseAllResources.map((item) => updateLikes(item, payload));
        break;
      case LINK_RESOURCE.DELETE.SUCCESS:
        draft.linkResources = removeItem(state.linkResources, payload);
        draft.linkAllResources = removeItem(state.linkAllResources, payload);
        break;
      case LINK_RESOURCE.POST.SUCCESS: {
        const resource = { ...payload };
        const source = payload.source;
        delete resource['source'];

        draft.linkResources.unshift(resource);
        draft.linkAllResources.unshift(resource);
        if (source == 'expertise') {
          draft.expertiseLinkedResources.linkResource = resource;
        }
        break;
      }
      case LINK_RESOURCE_VIEW.POST.SUCCESS:
      case LINK_RESOURCE.PUT.SUCCESS:
        updateOrAddResource('linkAllResources', 'linkResources', payload, draft);
        break;
      case LINK_RESOURCE_TAGS.POST.SUCCESS:
        draft.linkResources = updateTagsInResources(state.linkResources, payload);
        draft.linkAllResources = updateTagsInResources(state.linkAllResources, payload);
        break;
      case LINK_RESOURCE_LIKE.POST.SUCCESS:
        draft.linkResources = state.linkResources.map((item) => updateLikes(item, payload));
        draft.linkAllResources = state.linkAllResources.map((item) => updateLikes(item, payload));
        break;
      case TOOL_RESOURCE.DELETE.SUCCESS: {
        draft.toolResources = state.toolResources?.filter((resource) => resource.attributes?.pId !== payload);
        break;
      }
      case TOOL_RESOURCE.POST.SUCCESS: {
        draft.toolResources.unshift(payload);
        draft.toolAllResources.unshift(payload);
        break;
      }
      case TOOL_RESOURCE_VIEW.POST.SUCCESS:
      case TOOL_RESOURCE.PUT.SUCCESS:
      case TOOL_RESOURCE_RESOURCES.POST.SUCCESS:
        updateOrAddResource('toolAllResources', 'toolResources', payload, draft);
        break;
      case TOOL_RESOURCE_TAGS.POST.SUCCESS:
        draft.toolResources = updateTagsInResources(state.toolResources, payload);
        draft.toolAllResources = updateTagsInResources(state.toolAllResources, payload);
        break;
      case KNOWLEDGE_RESOURCE_COMMENTS.PUT.SUCCESS:
      case KNOWLEDGE_RESOURCE_COMMENTS_LIKE.POST.SUCCESS:
        draft.knowledgeResources = updateCommentsInResources(state.knowledgeResources, payload);
        draft.knowledgeAllResources = updateCommentsInResources(state.knowledgeAllResources, payload);
        break;
      case KNOWLEDGE_RESOURCE_COMMENTS.POST.SUCCESS:
        draft.knowledgeResources = addCommentToResource(state.knowledgeResources, payload);
        draft.knowledgeAllResources = addCommentToResource(state.knowledgeAllResources, payload);
        break;
      case COMMENT.DELETE.SUCCESS:
        draft.knowledgeResources = removeCommentFromResource(state.knowledgeResources, payload.comment);
        draft.knowledgeAllResources = removeCommentFromResource(state.knowledgeAllResources, payload.comment);
        break;
      case KNOWLEDGE_RESOURCE_LIKE.POST.SUCCESS:
        draft.knowledgeResources = state.knowledgeResources.map((item) => updateLikes(item, payload));
        draft.knowledgeAllResources = state.knowledgeAllResources.map((item) => updateLikes(item, payload));
        break;
      case TOOL_RESOURCE_COMMENTS.PUT.SUCCESS:
      case TOOL_RESOURCE_COMMENTS_LIKE.POST.SUCCESS:
        draft.toolResources = updateCommentsInResources(state.toolResources, payload);
        draft.toolAllResources = updateCommentsInResources(state.toolAllResources, payload);
        break;
      case TOOL_RESOURCE_COMMENTS.POST.SUCCESS:
        draft.toolResources = addCommentToResource(state.toolResources, payload);
        draft.toolAllResources = addCommentToResource(state.toolAllResources, payload);
        break;
      case TOOL_RESOURCE_COMMENTS.DELETE.SUCCESS:
        draft.toolResources = removeCommentFromResource(state.toolResources, payload);
        draft.toolAllResources = removeCommentFromResource(state.toolAllResources, payload);
        break;
      case TOOL_RESOURCE_LIKE.POST.SUCCESS:
        draft.toolResources = state.toolResources.map((item) => updateLikes(item, payload));
        draft.toolAllResources = state.toolAllResources.map((item) => updateLikes(item, payload));
        break;
      case TOOL_CONTAINED_RESOURCE_LIKE.POST.SUCCESS: {
        const userId = payload?.userId;
        draft.toolResources = state.toolResources.map((item) => {
          if (item?.attributes?.pId == payload?.sourceId) {
            const newKnowledgeResources = item?.knowledgeResources.map((resource) => {
              if (resource?.pId == payload?.elementId) {
                return containedItemWithUpdatedLikedBy(userId, resource);
              }
              return resource;
            });
            const newExpertiseResources = item?.expertiseResources.map((resource) => {
              if (resource?.pId == payload?.elementId) {
                return containedItemWithUpdatedLikedBy(userId, resource);
              }
              return resource;
            });
            const newLinkResources = item?.linkResources.map((resource) => {
              if (resource?.pId == payload?.elementId) {
                return containedItemWithUpdatedLikedBy(userId, resource);
              }
              return resource;
            });
            return {
              ...item,
              knowledgeResources: newKnowledgeResources,
              expertiseResources: newExpertiseResources,
              linkResources: newLinkResources
            };
          }
          return item;
        });
        break;
      }
      case TOOL_RESOURCE_DOCUMENTS.PUT.SUCCESS:
        draft.toolResources = updateResourceDocuments(state.toolResources, payload);
        draft.toolAllResources = updateResourceDocuments(state.toolAllResources, payload);
        break;
      case TOOL_RESOURCE_DOCUMENTS.POST.SUCCESS:
        draft.toolResources = addDocumentToResource(state.toolResources, payload);
        draft.toolAllResources = addDocumentToResource(state.toolAllResources, payload);
        break;
      case TOOL_RESOURCE_DOCUMENTS.DELETE.SUCCESS:
        draft.toolResources = removeDocumentFromResource(state.toolResources, payload);
        draft.toolAllResources = removeDocumentFromResource(state.toolAllResources, payload);
        break;
    }
  });

const containedItemWithUpdatedLikedBy = (userId, item) => {
  const likedByIds = item?.likedByIds ?? [];
  const index = likedByIds?.indexOf(userId) ?? -1;
  const newElementsById = [...likedByIds];
  if (index > -1) {
    newElementsById.splice(index, 1);
    return { ...item, likedByIds: newElementsById };
  } else {
    newElementsById.push(userId);
    return { ...item, likedByIds: newElementsById };
  }
};

export default resourceReducer;
