type SocialNetworkNames = 'facebook' | 'instagram' | 'twitter' | 'linkedin' | 'youtube' | 'website' | 'email';

export type IdParam = { id: string };

export enum ISearchRoutes {
  ALL = 'sve',
  MEMBERS = 'članice',
  ACTIONS = 'akcije',
  CONTESTS = 'konkursi',
  ORGANIZATIONS = 'organizacije',
  RESOURCES = 'resursi'
}

export enum ILocationType {
  COUNTRY = 'COUNTRY',
  ONLINE = 'ONLINE',
  BOTH = 'BOTH'
}

export enum IEventMemberType {
  USER = 'USER',
  ORGANIZATION = 'ORGANIZATION'
}

export interface IEventUser {
  imageUrl: string;
  name: string;
  id: number;
  memberType: IEventMemberType;
}

export interface ICalendarEvent {
  id?: number;
  title: string;
  desc: string;
  start: string;
  end: string;
  locationType: ILocationType;
  eventType: number;
  countryId?: number;
  createdBy: number;
  tags?: ITag[];
  combinedOrganizers?: IEventUser[];
  participants?: IEventUser[];
  link?: string;
  address?: string;
}

export interface ICalendarEventType {
  id: number;
  name: string;
}

export enum IAuthorApprovalType {
  PUBLIC = 'PUBLIC',
  AUTHOR_APPROVAL = 'AUTHOR_APPROVAL',
  AUTHOR_NON_APPROVAL = 'AUTHOR_NON_APPROVAL',
  NON_AUTHOR_APPROVAL = 'NON_AUTHOR_APPROVAL',
  NON_AUTHOR_NON_APPROVAL = 'NON_AUTHOR_NON_APPROVAL'
}

export enum IDocumentType {
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  PDF = 'PDF',
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT'
}

export const ICurrencyCode = {
  USD: '$',
  EUR: '€',
  RSD: 'RSD',
  KM: 'KM',
  HRK: 'kn'
};

export enum IApplicationType {
  LINK = 'LINK',
  DOCUMENT = 'DOCUMENT',
  APPLICATION = 'APPLICATION',
  ACTION = 'ACTION'
}
export enum ISupportType {
  PERCENT = 'PERCENT',
  VOTES = 'VOTES'
}

export enum IApplicantType {
  USER = 'USER',
  ORGANIZATION = 'ORGANIZATION',
  BOTH = 'BOTH'
}

export interface IContestAttributes {
  id: string;
  name: string;
  pId: number;
  imageUrl: string;
  budget: number;
  actionBudget?: number;
  groupActionBudget?: number;
  remainingBudget?: number;
  description: string;
  createdBy: number;
  applicationsDeadline: string;
  votingDeadline: string;
  published: boolean;
  organizerOrganizationId: number;
  applicationType: IApplicationType;
  applicantType: IApplicantType;
  organizerUser: IUser;
  organizerOrganization: IOrganizationAttributes;
  allCountries: boolean;
  allOrganizations: boolean;
  allIndividuals: boolean;
  minAge: number;
  maxAnge: number;
  voteNumber: number;
  isVotingActive?: boolean;
  isApplyActive?: boolean;
  applicationLink: string;
  supportType: ISupportType;
  participation: boolean;
  currencyId: number;
  likedByIds?: number[];
  allUserParticicpantsIds?: number[];
  seenByIds?: number[];
  commentsCount?: number;
  currency?: ICurrency;
}

export interface IContest {
  attributes: IContestAttributes;
  countries: ICountry[];
  organizations: IOrganization[];
  tags: ITag[];
  documents: IDocument[];
  platformApplications: IContestApplicationAttributes[];
  actionApplications: IActionAttributes[];
  comments: IComment[];
}

interface IAttributes {
  email: string;
  email_verified?: boolean;
  name?: string;
  sub: string;
}

interface IClient {
  endpoint?: string;
  fetchOptions?: any;
}

interface ICognitoUserSession {
  accessToken?: {
    jwtToken?: string;
    payload?: any;
  };
  idToken?: {
    jwtToken?: string;
    payload?: any;
  };
  refreshToken?: {
    token?: string;
  };
}

interface IPool {
  advancedSecurityDataCollectionFlag?: boolean;
  client?: IClient;
  clientId?: string;
  storage?: any;
  userPoolId?: string;
  wrapRefreshSessionCallback?: () => void;
}
export interface ICognitoUser {
  Session?: string;
  attributes: IAttributes;
  authenticationFlowType?: string;
  client?: IClient;
  keyPrefix?: string;
  pool?: IPool;
  preferredMFA?: string;
  signInUserSession?: ICognitoUserSession;
  storage?: any;
  userDataKey?: string;
  username?: string;
}

export enum AuthFormState {
  SignIn = 'signIn',
  ConfirmSignIn = 'confirmSignIn',
  SignUp = 'signUp',
  ConfirmSignUp = 'confirmSignUp',
  SignedIn = 'signedIn',
  SignOut = 'signOut',
  ChangePassword = 'changePassword',
  ForgotPassword = 'forgotPassword',
  ForgotPasswordConfim = 'forgotPasswordConfirm'
}

export enum Role {
  MEMBER = 'MEMBER',
  MODERATOR = 'MODERATOR'
}

export interface IMember {
  role: Role;
  member: IGenericMember;
}

export interface IUpdateMember {
  userId: number;
  role: Role;
}

export interface IGenericMember {
  name: string;
  imageUrl: string;
  pId: number;
  memberType?: string;
}

export interface IUserOrganization {
  role: Role;
  organization: IOrganizationAttributes;
}

export interface IUser {
  pId: number;
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  discourseUsername: string;
  discourseId: string;
  organization: string;
  title: string;
  showCustomTitle: boolean;
  headerWorkBioId?: number;
  imageUrl: string;
  bio: string;
  countryId: number;
  city: string;
  email: string;
  hobby: string;
  birthDate: string;
  pets: string;
  headerWorkBio?: IWorkBio;
  signature?: string;
  organizationsLogos: Array<string>;
  knowledgeResources?: IKnowledgeResource[];
  linkResources?: ILinkResource[];
  expertiseResources?: IExpertiseResource[];
  toolResources?: IToolResource[];
  actions?: IAction[];
  contests?: IContest[];
}

export interface IPersonalProfile extends IUser {
  attributes: IUser;
  tags: ITag[];
  countries: ICountry[];
  organizations: IUserOrganization[];
  workBios: IWorkBio[];
  socialNetworks: ISocialNetwork[];
  education: IEducation[];
  notificationSettings: INotificationSettings;
  knowledgeResources?: IKnowledgeResource[];
  linkResources?: ILinkResource[];
  expertiseResources?: IExpertiseResource[];
  toolResources?: IToolResource[];
  actions?: IAction[];
  contests?: IContest[];
}

export interface INotificationSettings {
  forumToastOn?: boolean;
  forumEmailOn?: boolean;
  actionsEmailOn?: boolean;
  actionsToastOn?: boolean;
  contestsEmailOn?: boolean;
  contestsToastOn?: boolean;
  resourcesEmailOn?: boolean;
  resourcesToastOn?: boolean;
  calendarEmailOn?: boolean;
  calendarToastOn?: boolean;
  commentsEmailOn?: boolean;
  commentsToastOn?: boolean;
  mentionsEmailOn?: boolean;
  mentionsToastOn?: boolean;
}

export interface IEducation {
  id: string;
  schoolOrCourse: string;
  certificate: string;
  toDate: string;
  fromDate: string;
  location: string;
}

export interface ISocialNetwork {
  id: string;
  name: SocialNetworkNames;
  url: string;
}

export interface ITag {
  pId: number;
  name: string;
  url?: string;
  id?: string;
  type?: string;
}

export interface ISimpleTag {
  pId: number;
  id?: string;
  name: string;
  type?: string;
}

export interface ISimpleApplicationBackers {
  applicationId: number;
  backers: number[];
}

export interface ICurrency {
  pId: number;
  name: string;
  code: string;
  id: string;
}
export interface IWorkBio {
  position: string;
  fromDate: string;
  toDate: string;
  workplace: string;
  location: string;
  id: string;
  pId: number;
  imageUrl: string;
}

export interface ICountry {
  pId: number;
  name: string;
  id: string;
}
export interface IOrganizationAttributes {
  pId: number;
  id: string;
  name: string;
  imageUrl: string;
  imageFile?: File;
  description?: string;
  about?: string;
  address: string;
  email: string;
  city: string;
  countryId: string;
  phoneNumber?: string;
  moderatorIds: number[];
}
export interface IOrganization {
  attributes: IOrganizationAttributes;
  projects: IProject[];
  socialNetworks: ISocialNetwork[];
  tags: ITag[];
  members?: IMember[];
  actions?: IAction[];
  contests?: IContest[];
  knowledgeResources?: IKnowledgeResource[];
  linkResources?: ILinkResource[];
  expertiseResources?: IExpertiseResource[];
  toolResources?: IToolResource[];
}

export interface IProject {
  id: string;
  pId: number;
  name: string;
  description: string;
  imageUrl?: string;
  fromDate: string;
  toDate: string;
  location: string;
}
export interface IActivity {
  type: string;
  payload: any;
}

export enum ISignupState {
  Roules,
  Conditions,
  Form,
  Password,
  EmailVerify,
  Avatar,
  Theme
}

export interface ISignupForm {
  firstname: string;
  lastname: string;
  organizationId?: number;
  organizationName: string;
  position: string;
  email: string;
  password?: string;
  confirmPassword?: string;
  username?: string;
  imageUrl?: string;
  invitationId?: string;
  isContinuingSignUp?: boolean;
  confirmedEmail: boolean;
  state: ISignupState;
}

export interface IDiscourseCategory {
  id: string;
  name: string;
  watched: boolean;
}

export interface INotification {
  pId: number;
  authorImageUrl?: string;
  authorName?: string;
  elementImageUrl?: string;
  relativeUrl?: string;
  absoluteUrl?: string;
  text: string;
  read: boolean;
  timestamp: string;
  notificationSource?: string;
  url?: string;
  allReceivers?: number[];
  toastReceivers?: number[];
  [propName: string]: any;
}

export interface INotifications {
  notifications: INotification[];
  unreadNotificationsCount: number;
}

export interface ICheckListItem {
  id?: string;
  text: string;
  actionId?: string;
  done?: boolean;
}

export interface IDocument {
  id?: string;
  pId?: number;
  url?: string;
  title?: string;
  file?: File;
  elementId?: number;
}

export interface IActionAttributes {
  id: string;
  pId: number;
  likedByIds?: number[];
  backedByIds?: number[];
  seenByIds?: number[];
  allUserParticipantsIds?: number[];
  name: string;
  imageUrl: string;
  description: string;
  endDate: string;
  published: boolean;
  backedPercentage?: number;
  organizerOrganizationId?: number;
  organizerUser?: IUser;
  organizerOrganization?: IOrganization;
  createdBy?: number;
  imageFile?: File;
  contestId?: number;
  contest?: IContestAttributes;
  isGroupApplication?: boolean;
  isVotingActive?: boolean;
  allTags?: ISimpleTag[];
  isBacked?: boolean;
  commentsCount?: number;
}

export interface IAction {
  attributes: IActionAttributes;
  tags: ITag[];
  participants: IGenericMember[];
  checkListItems: ICheckListItem[];
  documents: IDocument[];
  comments: IComment[];
  history: IActivityHistory[];
}

export interface IComment {
  id?: string;
  pId?: number;
  text: string;
  elementId: number;
  timestamp?: string;
  userId: number;
  parentId?: string;
  author?: IUser;
  likedByIds?: number[];
}

export interface ICardComments {
  elementId: number;
  comments: IComment[];
}

export interface IActivityHistory {
  id: string;
  author: IUser;
  text: string;
  timestamp: string;
}

export interface IContestApplicationAttributes {
  id?: string;
  pId?: number;
  content: string;
  createdBy: number;
  organizationId?: number;
  organization?: IOrganizationAttributes;
  user?: IUser;
  contestId: number;
  likedByIds?: number[];
  seenByIds?: number[];
  backedByIds?: number[];
  commentsCount?: number;
  isVotingActive?: boolean;
  contest?: IContestAttributes;
  allContestBackers?: ISimpleApplicationBackers[];
  knowledgeResourceIds?: number[];
  linkResourceIds?: number[];
  expertiseResourceIds?: number[];
  toolResourceIds?: number[];
}

export interface IContestApplication {
  attributes: IContestApplicationAttributes;
  comments: IComment[];
  documents: IDocument[];
  knowledgeResources: IKnowledgeResourceAttributes[];
  linkResources: ILinkResourceAttributes[];
  expertiseResources: IExpertiseResourceAttributes[];
  toolResources: IToolResourceAttributes[];
}

export interface IElegibleContests {
  contests: IContestAttributes[];
  count: number;
}

export enum AccessLevel {
  Public = 'public',
  Private = 'private',
  Protected = 'protected'
}

export enum ElementType {
  ACTION = 'ACTION',
  CONTEST = 'CONTEST',
  CONTEST_APPLICATION = 'CONTEST_APPLICATION',
  EXPERTISE_RESOURCE = 'EXPERTISE_RESOURCE',
  CONTEST_CONTEST_APPLICATION = 'CONTEST_CONTEST_APPLICATION',
  CONTEST_ACTION = 'CONTEST_ACTION',
  KNOWLEDGE_RESOURCE = 'KNOWLEDGE_RESOURCE',
  TOOL_RESOURCE = 'TOOL_RESOURCE',
  LINK_RESOURCE = 'LINK_RESOURCE'
}

export const HomeElementKeys = {
  contests: ElementType.CONTEST,
  actions: ElementType.ACTION,
  knowledgeResources: ElementType.KNOWLEDGE_RESOURCE,
  toolResources: ElementType.TOOL_RESOURCE,
  linkResources: ElementType.LINK_RESOURCE,
  expertiseResources: ElementType.EXPERTISE_RESOURCE
};

export const ElementKeysToTYpe = {
  [ElementType.ACTION]: 'actions',
  [ElementType.CONTEST]: 'contests',
  [ElementType.EXPERTISE_RESOURCE]: 'expertiseResources',
  [ElementType.TOOL_RESOURCE]: 'toolResources',
  [ElementType.KNOWLEDGE_RESOURCE]: 'knowledgeResources',
  [ElementType.LINK_RESOURCE]: 'linkResources'
};

export interface IExpertiseResourceAttributes {
  allTags: any;
  id?: string;
  pId?: number;
  timestamp?: string;
  commentsCount?: number;
  viewsCount?: number;
  imageUrl: string;
  name: string;
  expertiseArea?: string;
  description?: string;
  createdBy?: number;
  expertiseLocation?: string;
  email?: string;
  city?: string;
  countryId?: number;
  seenByIds?: number[];
  likedByIds?: number[];
}

export interface IExpertiseResourceProject {
  id?: string;
  pId?: number;
  creatorOrganizationId?: number;
  creatorOrganization?: IOrganizationAttributes;
  project: string;
  resourceType?: string;
  organizationId?: number;
  workplace?: string;
  description: string;
  projectLink?: string;
  resourceId?: number;
  linkId?: number;
  knowledgeId?: number;
}

export interface IExpertiseResource {
  attributes: IExpertiseResourceAttributes;
  tags: ITag[];
  projects: IExpertiseResourceProject[];
  comments: IComment[];
  toolResources?: IToolResourceAttributes[];
}

export interface ILinkResourceAttributes {
  id?: string;
  pId?: number;
  creatorOrganizationId?: number;
  creatorOrganization?: IOrganizationAttributes;
  resourceType?: string;
  timestamp?: string;
  imageUrl?: string;
  link: string;
  name: string;
  description: string;
  createdBy: number;
  seenByIds?: number[];
  likedByIds?: number[];
  allTags?: ISimpleTag[];
}

export interface ILinkResource {
  attributes: ILinkResourceAttributes;
  tags?: ITag[];
  toolResources?: IToolResourceAttributes[];
}

export interface IKnowledgeResourceAttributes {
  likedByIds: number[];
  seenByIds: number[];
  timestamp?: string;
  commentsCount?: number;
  viewsCount?: number;
  resourceType?: string;
  id?: string;
  pId?: number;
  imageUrl?: string;
  contentUrl: string;
  name: string;
  description: string;
  createdBy: number;
  documentType: IDocumentType;
  authorRights: IAuthorApprovalType;
  author?: IUser;
  creatorOrganization?: IOrganizationAttributes;
  allTags?: ISimpleTag[];
  creatorOrganizationId?: number;
}

export interface IKnowledgeResource {
  attributes: IKnowledgeResourceAttributes;
  comments: IComment[];
  tags: ITag[];
  toolResources?: IToolResourceAttributes[];
}

export interface IToolResourceAttributes {
  id?: string;
  pId?: number;
  creatorOrganizationId?: number;
  author?: IUser;
  creatorOrganization?: IOrganizationAttributes;
  resourceType?: string;
  timestamp?: string;
  imageUrl?: string;
  commentsCount?: number;
  viewsCount?: number;
  name: string;
  description?: string;
  createdBy?: number;
  seenByIds?: number[];
  likedByIds?: number[];
  allTags?: ISimpleTag[];
}

export interface IToolResource {
  attributes: IToolResourceAttributes;
  tags: ITag[];
  knowledgeResources: IKnowledgeResourceAttributes[];
  linkResources: ILinkResourceAttributes[];
  expertiseResources: IExpertiseResourceAttributes[];
  comments: IComment[];
  documents: IDocument[];
}
